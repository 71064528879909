<template>
  <div class="font-manrope container">
    <div class="min-h-screen flex items-center justify-center">
      <slot />
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n();

const title = t("home.advantagesCards.title");
const description = t("home.advantagesCards.subTitle");
useSeoMeta({
  title: title,
  ogDescription: description,
  ogType: "website",
});
</script>
