<template>
  <div class="bg-white">
    <transition name="slide-fade">
      <mobile-menu
        v-if="isMenuOpen"
        @menu="toggleMenu"
        class="fixed lg:hidden"
      />
    </transition>
    <Header @menu="toggleMenu" ref="header" class="container py-3 px-5" />
    <div
      ref="container"
      :class="
        routesWithoutLayout.includes(route.path)
          ? ''
          : 'container lg:grid lg:grid-cols-4 gap-9 px-5 mt-6 lg:mt-20'
      "
    >
      <div
        v-if="!routesWithoutLayout.includes(route.path)"
        class="lg:block hidden px-1 w-full"
      >
        <div
          class="text-brand-secondary mt-0 text-3xl leading-9 justify-center"
        >
          <h1 class="">{{ t("profile.welcome") }} &nbsp</h1>
          <h1 class="font-extrabold">{{ user }}</h1>
        </div>
        <div class="mt-6 border border-alto-300 rounded-sm divide-y">
          <template v-for="tab in tabs" :key="tab.title">
            <nuxt-link
              :to="localePath(tab.path)"
              :target="tab?.target ?? '_self'"
              class="py-4 flex gap-3 items-center leading-5 lg:pl-10 text-black font-normal hover:cursor-pointer hover:font-bold hover:text-cerise-red-600"
              active-class="!text-cerise-red-600 !font-bold"
            >
              {{ t(`tabs.${tab.key}`) }}

              <span v-if="tab?.target === '_blank'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2rem"
                  height="1.2rem"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#e72b6f"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 6H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6m-7 1l9-9m-5 0h5v5"
                  />
                </svg>
              </span>
            </nuxt-link>
          </template>
        </div>
      </div>

      <!--      mobile menu -->
      <div
        v-if="!routesWithoutLayout.includes(route.path)"
        class="text-center lg:hidden block px-1 w-full"
      >
        <div class="text-cerise-red-600 flex text-xl leading-6 justify-center">
          <h1 class="">{{ t("profile.welcome") }} &nbsp</h1>
          <h1 class="font-extrabold">{{ user }}</h1>
        </div>
        <div
          class="mt-4 border border-alto-300 rounded-sm"
          :class="{ 'divide-y': expandTabsMenu }"
        >
          <template v-for="tab in tabs" :key="tab.title">
            <nuxt-link
              :to="localePath(tab.path)"
              @click="expandTabsMenu = !expandTabsMenu"
              :target="tab?.target ?? '_self'"
              class="py-4 flex items-center text-black font-normal"
              :class="{ hidden: !expandTabsMenu }"
              active-class="!text-cerise-red-600 !font-bold !flex"
            >
              <nuxt-img
                src="/images/ios-menu.svg"
                class="lg:hidden block bg-transparent w-7 ml-7"
                v-if="route.path === localePath(tab.path) && !expandTabsMenu"
              />
              <div
                :class="
                  route.path === localePath(tab.path) && !expandTabsMenu
                    ? 'pr-14'
                    : ''
                "
                class="leading-5 mx-auto flex gap-3"
              >
                <span v-if="tab?.target === '_blank'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2rem"
                    height="1.2rem"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#e72b6f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6m-7 1l9-9m-5 0h5v5"
                    />
                  </svg>
                </span>
                {{ t(`tabs.${tab.key}`) }}
              </div>
            </nuxt-link>
          </template>
        </div>
      </div>

      <slot />
      <LoginPopUp
        v-if="!authenticated"
        @close="store.close"
        :is-open="store.visibility"
      />
    </div>
    <Footer ref="footer" class="px-5" />
  </div>
</template>

<script setup>
import { useLoginPopUp } from "@/stores/login-popup-visibility";

const customer_name = useCookie("customer_name");
const user = computed(() => customer_name.value ?? "Usuario");
const header = ref(null);
const footer = ref(null);
const container = ref(null);
const isMenuOpen = ref(false);
const expandTabsMenu = ref(false);

const route = useRoute();

const { t } = useI18n();
const localePath = useLocalePath();

const tabs = ref([
  {
    key: "profile",
    title: t("tabs.profile"),
    path: "/profile",
  },
  {
    key: "updateProfile",
    title: t("tabs.updateProfile"),
    path: "/profile/update",
  },
  {
    key: "badges",
    title: t("tabs.badges"),
    path: "/profile/badges",
  },
  {
    key: "complaints",
    title: t("tabs.complaints"),
    path: "/incidencias-family-club",
  },
]);

function toggleMenu(v) {
  isMenuOpen.value = v;
}

const routesWithoutLayout = [
  "/",
  "/vip-club",
  "/contact",
  "/incidencias-family-club",
  "/hazte-socio",
  "/legal/reglamento-family-card",
  "/legal/regulamento-family-card",
  "/legal/reglamento-family-card-vip",
  "/legal/regulamento-family-card-vip",
  "/verify-email",
  "/email-sent",
  "/legal/condiciones-promocionales",
  "/torna-te-membro",
  "/legal/condicoes-promocionais",
  "/pt",
  "/pt/",
  "/pt/vip-club",
  "/pt/contact",
  "/pt/incidencias-family-club",
  "/pt/torna-te-membro",
  "/pt/login",
  "/pt/legal/reglamento-family-card",
  "/pt/legal/reglamento-family-card-vip",
  "/pt/verify-email",
  "/pt/email-sent",
  "/pt/legal/condicoes-promocionais",
  "/pt/legal/regulamento-family-card",
  "/pt/legal/regulamento-family-card-vip",
  "/politica-de-privacidad",
  "/pt/politica-de-privacidade"
];

onMounted(() => {
  const height = header.value.$el.clientHeight + footer.value.$el.clientHeight;
  container.value.style.minHeight = "calc(100vh - " + height + "px)";
});

const store = useLoginPopUp();
const authenticated = useCookie("authenticated").value;

const title = t("home.advantagesCards.title");
const description = t("home.advantagesCards.subTitle");
//SEO meta
useSeoMeta({
  title: title,
  ogDescription: description,
  ogType: "website",
});
</script>
